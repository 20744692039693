'use strict';

// stylesheet import
import '../scss/main.scss';

import { createApp } from 'vue';
import { store } from './store.js';
import App from './vue/app.vue';
import HomeSlideVisual from './vue/HomeSlideVisual.vue';
import HomeSlideMessage from './vue/HomeSlideMessage.vue';
import { IntersectionObserverToggleClass } from './modules/class-intersection-observer-toggleclass.js';
import { IsLegacyIE } from './modules/checking-legacy-ie.js';
import { FindOS } from './modules/checking-os.js';
import { FindBrowser } from './modules/checking-browser.js';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Vivus from 'vivus';
const $ = require('jQuery');

// Vue.js
const app = createApp({});
app.component('app', App)
  .component('home-slide-visual', HomeSlideVisual)
  .component('home-slide-message', HomeSlideMessage)
  .use(store)
  .mount('#app');
  
// jQuery
$(function() {
  
  // drawer navi
  $('.js-hamburger').on('click', function() {
    $('.js-drawer').slideToggle('c-drawer--opened');
    $(this).toggleClass('c-hamburger--close');
    $('.js-header-effect').toggleClass('js-header-add-bg');
  });
  $(window).on('resize', function() {
    $('.js-drawer').slideUp('c-drawer--opened');
    $('.js-hamburger').removeClass('c-hamburger--close');
    $('.js-header-effect').removeClass('js-header-add-bg');
  });

  // accordion panel
  $('.js-accordion-trigger').on('click', function() {
    if($(this).hasClass('js-accordion-trigger--more')) {
      $(this).prev().slideToggle();
    } else {
      $(this).next().slideToggle();
    }
    $(this).toggleClass('__summary--opened');
    if($(this).find('.js-accordion-rollover')) {
      if($(this).hasClass('__summary--opened')) {
        $(this).find('.js-accordion-rollover').attr('src', $(this).find('.js-accordion-rollover').attr('data-active'));
      } else {
        $(this).find('.js-accordion-rollover').attr('src', $(this).find('.js-accordion-rollover').attr('data-src'));
      }
    }
  });

  // header effect
  $(window).on('scroll road', function() {
    if(window.scrollY === 0) {
      $('.js-header-effect').removeClass('js-header-add-bg');
    } else {
      $('.js-header-effect').addClass('js-header-add-bg');
    }
  });

  // dropdown menu effect
  $('.js-dropdown-menu-trigger').on('mouseenter', function() {
    const imagePath = $(this).find('.js-dropdown-menu-rollover').attr('data-hover', )
    $(this).find('.js-dropdown-menu-rollover').attr('src', imagePath);
  });
  $('.js-dropdown-menu-trigger').on('mouseleave', function() {
    const imagePath = $(this).find('.js-dropdown-menu-rollover').attr('data-src', )
    $(this).find('.js-dropdown-menu-rollover').attr('src', imagePath);
  });
  $('.js-dropdown-overlay-trigger').on('mouseenter', function() {
    $('.js-dropdown-overlay').show();
  });
  $('.js-dropdown-overlay-trigger').on('mouseleave', function() {
    $('.js-dropdown-overlay').hide();
  });

  // member thumbnail
  if(0 < $('.js-member-thumbnail').length) {
    if(window.matchMedia('(hover: hover) and (pointer: fine)').matches) {
      $('.js-member-thumbnail').on('mouseenter', function() {
        $(this).addClass('__thumbnail--shown');
      });
      $('.js-member-thumbnail').on('mouseleave', function() {
        $(this).removeClass('__thumbnail--shown');
      });
    } else {
      let startY = null;
      let endY = null;
      const touchStart = (event) => {
        event.preventDefault();
        startY = event.touches[0].pageY;
      }
      const swipe = (event) => {
        event.preventDefault();
        endY = event.touches[0].pageY;
      }
      const touchEnd = (event) => {
        event.preventDefault();
        if(0 < (endY - startY)) {
          document.querySelector('.js-member-thumbnail').classList.remove('__thumbnail--shown');
        } else {
          document.querySelector('.js-member-thumbnail').classList.add('__thumbnail--shown');
        }
        
      }
      document.querySelector('.js-member-thumbnail').addEventListener('touchmove', swipe);
      document.querySelector('.js-member-thumbnail').addEventListener('touchstart', touchStart);
      document.querySelector('.js-member-thumbnail').addEventListener('touchend', touchEnd);
    }
  }

  // copy url
  $('.js-copy-url').on('click', function(event) {
    const targetUrl = event.target.getAttribute('data-url');
    navigator.clipboard.writeText(targetUrl);
    alert('URLがコピーされました');
  });

});

// GSAP
gsap.registerPlugin(ScrollTrigger);
gsap.fromTo('.js-diagram-arrow-target',
  {
    autoAlpha: 0
  },
  {
    x: 0,
    autoAlpha: 1,
    delay: 0.2,
    duration: 1.2,
    ease: 'power2.out',
    scrollTrigger: {
      trigger: '.js-diagram-arrow-trigger',
      start: 'top 80%'
    }
  }
);
gsap.fromTo('.js-diagram-hexagon-target-1st',
  {
    autoAlpha: 0
  },
  {
    autoAlpha: 1,
    delay: 0.2,
    duration: 1.2,
    ease: 'power2.out',
    scrollTrigger: {
      trigger: '.js-diagram-hexagon-trigger',
      start: 'top 80%',
    }
  }
);
gsap.fromTo('.js-diagram-hexagon-target-2nd',
  {
    autoAlpha: 0
  },
  {
    autoAlpha: 1,
    delay: 1.4,
    duration: 1.2,
    ease: 'power2.out',
    scrollTrigger: {
      trigger: '.js-diagram-hexagon-trigger',
      start: 'top 80%',
    }
  }
);
const scrollFadeinItems = gsap.utils.toArray('.js-scroll-fadein-target');
scrollFadeinItems.forEach((item) => {
  gsap.fromTo(item,
    {
      y: 50,
      autoAlpha: 0
    },
    {
      y: 0,
      autoAlpha: 1,
      delay: 0.6,
      duration: 1.2,
      ease: 'power2.out',
      scrollTrigger: {
        trigger: item.parentNode,
        start: 'top 80%'
      }
    }
  );
});
const DiagramCircleTrigger = document.querySelector('.js-diagram-circle-trigger');
if(DiagramCircleTrigger) {
  ScrollTrigger.create({
    trigger: '.js-diagram-circle-trigger',
    start: 'top 80%',
    onEnter: () => {
      // Vivus
      new Vivus('path-drawing-left', 
        {
          duration: 300,
          start: 'autostart',
          type: 'oneByOne',
        }
      );
      new Vivus('path-drawing-right', 
        {
          duration: 300,
          start: 'autostart',
          type: 'oneByOne',
        }
      );
    },
    once: true,
  });
}

// Intersection Observer
new IntersectionObserverToggleClass({
  selector: '.observe_target', 
  className: 'observed', 
  toggle: true
});

// checking legacy IE
IsLegacyIE();

// checking OS
FindOS();

// checking browser
FindBrowser();