<template>
  <section class="l-wrapper p-background-section js-slide-fade">
    <picture 
      class="__background __slide" 
      :class="data.currentSlide === 1 ? '__slide--current' : data.currentSlide === 3 ? '__slide--next' : ''"
      :style="{ transition: `opacity ${data.speed}ms ease 0.1s`}"
    >
      <img class="__image" :src="`${props.assetsPath}assets/images/site/home/home_image_slide_01.jpg`" alt="">
    </picture>
    <picture 
      class="__background __slide" 
      :class="data.currentSlide === 2 ? '__slide--current' : data.currentSlide === 1 ? '__slide--next' : ''"
      :style="{ transition: `opacity ${data.speed}ms ease 0.1s`}"
    >
      <img class="__image" :src="`${props.assetsPath}assets/images/site/home/home_image_slide_02.jpg`" alt="">
    </picture>
    <picture 
      class="__background __slide" 
      :class="data.currentSlide === 3 ? '__slide--current' : data.currentSlide === 2 ? '__slide--next' : ''"
      :style="{ transition: `opacity ${data.speed}ms ease 0.1s`}"
    >
      <img class="__image" :src="`${props.assetsPath}assets/images/site/home/home_image_slide_03.jpg`" alt="">
    </picture>
  </section>
  <section class="l-wrapper p-foreground-section">
    <div class="l-container">
      <div class="l-inner">
        <div class="__block-depth-1 u-flex-center-center u-flex-column">
          <div class="__block-depth-2 __block-depth-2--top u-flex-grow-1 u-flex-shrink-0 js-slide-fade">
            <div 
              class="__block-depth-3 u-flex-center-center __slide"
              style="position: absolute; left: 0; top: 0; width: 100%;"
              :class="data.currentSlide === 1 ? '__slide--current' : data.currentSlide === 3 ? '__slide--next' : ''"
              :style="{ transition: `opacity ${data.speed}ms ease 0.1s`}"
            >
              <picture>
                <source :srcset="`${props.assetsPath}assets/images/site/home/home_image_slide_copy_01.png`" media="(min-width: 769px)" />
                <source :srcset="`${props.assetsPath}assets/images/site/home/home_image_slide_copy_mobile_01.png`" media="(max-width: 768px)" />
                <img :src="`${props.assetsPath}assets/images/site/home/home_image_slide_copy_01.png`" alt="クライアントと、輝く未来をつなぐ掛橋になる。" />
              </picture>
            </div>
            <div 
              class="__block-depth-3 u-flex-center-center __slide"
              style="position: absolute; left: 0; top: 0; width: 100%;"
              :class="data.currentSlide === 2 ? '__slide--current' : data.currentSlide === 1 ? '__slide--next' : ''"
              :style="{ transition: `opacity ${data.speed}ms ease 0.1s`}"
            >
              <picture>
                <source :srcset="`${props.assetsPath}assets/images/site/home/home_image_slide_copy_02.png`" media="(min-width: 769px)" />
                <source :srcset="`${props.assetsPath}assets/images/site/home/home_image_slide_copy_mobile_02.png`" media="(max-width: 768px)" />
                <img :src="`${props.assetsPath}assets/images/site/home/home_image_slide_copy_02.png`" alt="「事業」と「財務」の両面から、会社を立て直す。" />
              </picture>
            </div>
            <div 
              class="__block-depth-3 u-flex-center-center __slide"
              style="position: absolute; left: 0; top: 0; width: 100%;"
              :class="data.currentSlide === 3 ? '__slide--current' : data.currentSlide === 2 ? '__slide--next' : ''"
              :style="{ transition: `opacity ${data.speed}ms ease 0.1s`}"
            >
              <picture>
                <source :srcset="`${props.assetsPath}assets/images/site/home/home_image_slide_copy_03.png`" media="(min-width: 769px)" />
                <source :srcset="`${props.assetsPath}assets/images/site/home/home_image_slide_copy_mobile_03.png`" media="(max-width: 768px)" />
                <img :src="`${props.assetsPath}assets/images/site/home/home_image_slide_copy_03.png`" alt="実践的コンサルティングで、中小企業を支援する。" />
              </picture>
            </div>
          </div>
          <div class="__block-depth-2 __block-depth-2--bottom u-flex-grow-0 u-flex-shrink-1">
            <ul class="c-slide-navi u-flex-center-stretch __list js-slide-navi">
              <li class="__item" :class="data.currentSlide === 1 ? '__current' : ''" :style="{ transition: `background ${data.speed}ms ease 0.1s`}" @click="navigation(1)"></li>
              <li class="__item" :class="data.currentSlide === 2 ? '__current' : ''" :style="{ transition: `background ${data.speed}ms ease 0.1s`}" @click="navigation(2)"></li>
              <li class="__item" :class="data.currentSlide === 3 ? '__current' : ''" :style="{ transition: `background ${data.speed}ms ease 0.1s`}" @click="navigation(3)"></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
  import { reactive, onMounted } from 'vue';
  const props = defineProps({
    assetsPath: String,
  });
  const data = reactive({
    currentSlide: 1,
    slideCount: 3,
    duration: 4000,
    speed: 1500
  });
  const play = () => {
    if(data.currentSlide < data.slideCount) {
      data.currentSlide++;
    } else {
      data.currentSlide = 1;
    }
  }
  const navigation = (index) => {
    data.currentSlide = index;
  }
  onMounted(() => {
    setInterval(() => {
      play();
    }, data.duration);
  });
</script>