<template>
  <div class="__block-depth-1 u-flex-center-center js-slide-fade">
    <div
      class="__message __message--large u-align-center __slide"
      :class="data.currentSlide === 1 ? '__slide--current' : data.currentSlide === 3 ? '__slide--next' : ''"
      :style="{ transition: `opacity ${data.speed}ms ease 0.1s`}"
    >
      <!-- <span class="u-inline-block">前進のための</span>
      <span class="u-inline-block">知恵と力を。</span> -->
      <img class="__image" :src="`${props.assetsPath}assets/images/site/home/home_message_01.jpg`" alt="前進のための知恵と力を。" />
    </div>
    <div 
      class="__message __slide"
      style="display: none;"
      :class="data.currentSlide === 2 ? '__slide--current' : data.currentSlide === 1 ? '__slide--next' : ''"
      :style="{ transition: `opacity ${data.speed}ms ease 0.1s`}"
    >
      <span class="u-inline-block">借りてきた戦略では、</span>
      <span class="u-inline-block">企業の底力は引き出せない。</span>
      <br>
      <span class="u-inline-block">オーダーメイドで仕立てる</span>
      <span class="u-inline-block">テイラーのように、</span>
      <br>
      <span class="u-inline-block">丁寧にカウンセリングし、</span>
      <span class="u-inline-block">フィットする戦略を導き出す。</span>
      <br>
      <br>
      <span class="u-inline-block">成功している企業には、</span>
      <span class="u-inline-block">組織に適した緻密な</span>
      <span class="u-inline-block">戦略がある。</span>
      <br>
      <span class="u-inline-block">成長している企業には、</span>
      <span class="u-inline-block">安心して戦略を実行できる</span>
      <span class="u-inline-block">資金がある。</span>
    </div>
    <div 
      class="__message __slide"
      style="display: none;"
      :class="data.currentSlide === 3 ? '__slide--current' : data.currentSlide === 2 ? '__slide--next' : ''"
      :style="{ transition: `opacity ${data.speed}ms ease 0.1s`}"
    >
      <span class="u-inline-block">机上の空論は語らない。</span>
      <span class="u-inline-block">専門用語をならべない。</span>
      <br>
      <span class="u-inline-block">汗をかき、現場まで踏み込んで、</span>
      <br>
      <span class="u-inline-block">クライアントの想いに最高の</span>
      <span class="u-inline-block">成果で応えるコンサルタント。</span>
      <br>
      <br>
      <span class="u-inline-block">限界を感じている経営者に、希望の光を。</span>
      <br>
      <span class="u-inline-block">日本経済を支える中小企業に、知恵と力を。</span>
    </div>
    <div class="__block-depth-2">
      <a :href="`${props.linkPath}`" class="__button u-button-rounded u-button-gray">
        <img class="__icon" :src="`${props.assetsPath}assets/images/site/common/common_icon_arrow_white.svg`" alt="" />
        <span class="__copy">コーポレートブランドはこちら</span>
      </a>
    </div>
  </div>
</template>

<script setup>
  import { reactive, onMounted } from 'vue';
  const props = defineProps({
    assetsPath: String,
    linkPath: String
  });
  const data = reactive({
    currentSlide: 1,
    slideCount: 3,
    duration: 4000,
    speed: 1500
  });
  const play = () => {
    if(data.currentSlide < data.slideCount) {
      data.currentSlide++;
    } else {
      data.currentSlide = 1;
    }
  }
  const navigation = (index) => {
    data.currentSlide = index;
  }
  onMounted(() => {
    // setInterval(() => {
    //   play();
    // }, data.duration);
  });
</script>
